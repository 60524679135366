
import JnHomeMainStrip from "@/views/home/HomeMainStrip.vue";
import JnHomeAiChatStrip from "@/views/home/HomeAiChatStrip.vue";
import JnHomeSecondStrip from "@/views/home/HomeSecondStrip.vue";
import JnMainNewsStrip from "@/views/common/MainNewsStrip.vue";
import JnHomeServicesStrip from "@/views/home/HomeServicesStrip.vue";
import JnCardsStrip from "@/views/common/CardsStrip.vue";
import JnCookieBanner from "@/views/common/CookieBanner.vue";
import JnHomeRegistrazioneStrip from "@/views/home/HomeRegistrazioneStrip.vue";
import JnHomeNewsletterStrip from "@/views/home/HomeNewsletterStrip.vue";
import JnHomeDownloadAppStrip from "@/views/home/HomeDownloadAppStrip.vue";
import JnHomeConcorsiStrip from "@/views/home/HomeConcorsiStrip.vue";
import JnHomeTagStrip from "@/views/home/HomeTagStrip.vue";
import JnWhatsApp from "@/views/common/WhatsApp.vue";
import { Component, Vue } from "vue-property-decorator";
import { SearchData } from "@/models/search.model";
import { CategoriesAreeTematiche } from "@/models/enums.model";
import MetaInfo from "vue-meta";
import { EsService } from "@/services/es.service";
import { Picklist } from "@/models/picklist.model";

@Component({
  components: {
    JnHomeMainStrip,
    JnHomeAiChatStrip,
    JnHomeSecondStrip,
    JnMainNewsStrip,
    JnHomeConcorsiStrip,
    JnHomeNewsletterStrip,
    JnHomeDownloadAppStrip,
    JnHomeRegistrazioneStrip,
    JnHomeServicesStrip,
    JnCardsStrip,
    JnHomeTagStrip,
    JnCookieBanner,
    JnWhatsApp
  },
  metaInfo(this: Home): MetaInfo {
    return { title: "JuraNews - Home" };
  },
})
export default class Home extends Vue {
  areeTematicheSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    featured: 1,
    perPage: 12,
    idCategory: CategoriesAreeTematiche,
    withText: true,
  });

  alert = false;
  alertMessage = "";
  alertTimeout = 30000;
  
  inEvidenza: Picklist[] = [];

  attendants = [
      {
        app: 'whatsapp',
        label: 'Contatta il',
        name: 'Servizio Clienti',
        number: '3297570641',
        avatar: {
          src: 'https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4',
          alt: 'Servizio Clienti'
        }
      },
    ]

  async mounted() {
    this.inEvidenza = await EsService.getPicklist("home-page-in-evidenza");
    
    /*EventBus.$on("logged-paywall-not-ok", (maxNumSessionsReached: boolean) => {
      console.log("NNOOOOOOOO")
      if (maxNumSessionsReached) {
        this.alert = true;
        this.alertMessage = "Hai raggiunto il numero massimo di accessi contemporanei consentiti ai contenuti riservati. Per aumentare il limite puoi acquistare un'estensione di licenza. Puoi comunque continuare ad utilizzare i servizi previsti della tua area riservata (es. dossier, etc.)";
      }
    });*/
  }
}
