
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class JnHomeAiChatStrip extends Vue {

  goToAiChat() {
    this.$router.push('/ai/chat');
  }
}
